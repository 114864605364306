import React from "react";
import { 
  Seo, 
  Link, 
  Section, 
  Container, 
} from "@components";
import { PageLayout } from "../../components/site/layout/page-layout";
import { RightSidebar } from "../../components/site/product";

import { StaticImage } from "gatsby-plugin-image";

const OtherMarketPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      {/* Title Text start */}
      <div className="bg-[#122331] w-full">
        <Section>
          <Container className="py-[100px] md:py-[30px]">
            <div className="md:flex justify-between items-center">
              <div className="w-full md:w-[450px]">
                <h1 className="w-fit m-auto text-[40px] leading-[50px] md:ml-0 md:text-[53px] md:leading-[70px] font-semibold text-center text-[#ffffff] border-b-[6px] border-[#B21C7A] pb-[18px]">
                  Industries
                </h1>
              </div>
              <div className="w-full md:w-1/2 mt-[58px] md:mt-0">
                <StaticImage
                  src={`../../components/site/markets/images/industries.png`}
                  className="w-full md:max-w-[593px] h-auto"
                  alt="Mobile App and API Protection | Approov"
                  objectFit="contain"
                />
              </div>
            </div>
          </Container>
        </Section>
      </div>
      {/* Title Text end */}
      <Section>
        <Container className="pt-[20px] pb-[50px] md:pt-[60px] md:pb-[70px] md:flex justify-between">
          <div className="md:w-full md:mr-[38px]">
            <div className="bg-[#f2f5f9] rounded-[10px] py-[30px] px-[20px] lg:py-[40px] lg:px-[50px]">
              <div className="mb-[25px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] text-left lg:text-center my-0">
                  Social Media
                </h2>
                <div className="bg-[#B21C7A] h-[4px] mt-[10px] w-[162px] lg:mt-[15px] lg:w-[230px] lg:mx-auto"></div>
                <div className="text-[#262727] text-[17px] mt-[10px] leading-[29px] font-medium lg:mt-[15px]">
                  With large social networks now commonplace, it is not surprising that botnets are used to generate
                  and rapidly disseminate fake and misleading content through social APIs. With Approov, these
                  common abuse vectors can be blocked:
                </div>
              </div>
              <div className="w-full block flex-wrap lg:flex mt-[16px] md:mt-[50px]">
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Fake Account Creation
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Fake accounts are created for the purposes of spamming other real users or automating fake
                        interactions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-0">
                  <p className="leading-[25px] text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Provides <Link href="/mobile-app-security/rasp/api-security/" className="!underline">app authentication</Link> to ensure account onboarding only occurs through your legitimate app,
                        making the mass automation of account onboarding by attackers impractical.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Spamming
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Through fake accounts, automated posts and interactions can distort facts and influence trends and
                        sentiment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Restricts authors to posting only through your genuine apps.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[50px] md:mt-[40px] lg:flex justify-between">
              <StaticImage
                src={`../../components/site/customers/images/felyx.jpg`}
                className="w-full lg:max-w-[330px] h-[365px] h-auto rounded-[10px]"
                alt="Felyx image"
                objectFit="cover"
              />
              <div className="w-full lg:ml-[35px] pt-[20px]">
                <div className="lg:text-[17px] lg:leading-[29px] text-black">
                  Felyx is an innovative and forward-thinking scale-up that is focused on revolutionizing urban transportation across the Netherlands and Belgium. They achieve this through their shared e-moped solutions that offer sustainable and efficient transportation to all.
                </div>
                <Link href="/customer/felyx/" className="!no-underline">
                  <div className="text-white bg-[#78A65A] mt-[25px] px-[38px] py-[16px] rounded-[7px] font-medium text-[16px] leading-[28px] text-center md:w-fit transition hover:bg-[#1b7609]">
                    Felyx's Customer Story
                  </div>
                </Link>
              </div>
            </div>

            <div className="mt-[50px] md:mt-[40px] bg-[#f2f5f9] rounded-[10px] py-[30px] px-[20px] lg:py-[40px] lg:px-[50px]">
              <div className="mb-[25px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] text-left lg:text-center my-0">
                  Multiplayer Gaming & Gambling
                </h2>
                <div className="bg-[#B21C7A] h-[4px] mt-[10px] w-[162px] lg:mt-[15px] lg:w-[230px] lg:mx-auto"></div>
                <div className="text-[#262727] text-[17px] mt-[10px] leading-[29px] font-medium lg:mt-[15px]">
                  As mobile multiplayer games get more popular, gaming companies are seeing the same reverse
                  engineering techniques used to compromise banking and finance apps, now being used to take over
                  accounts, steal personal credentials or drain real or virtual winnings. Approov's ability to
                  identify, verify and certify apps, helps eliminate:
                </div>
              </div>
              <div className="w-full block flex-wrap lg:flex mt-[16px] md:mt-[50px]">
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Modified Game Capabilities
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Versions of an app may circulate that incorporate cheating capabilities which undermine player
                        experience and game stickiness.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-0">
                  <p className="leading-[25px] text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Allows only official, unmodified versions of apps to access your backend API services. Modified
                        and augmented apps fail.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[50px] md:mt-[40px] lg:flex justify-between lg:flex-row-reverse">
              <StaticImage
                src={`../../components/site/customers/images/Genopets.png`}
                className="w-full lg:max-w-[390px] h-[365px] h-auto rounded-[10px]"
                alt="Background image of man standing in front of Sixt rental car; close up of a smartphone showing Sixt app in foreground."
                objectFit="cover"
              />
              <div className="w-full lg:mr-[35px] pt-[20px]">
                <div className="lg:text-[17px] lg:leading-[29px] text-black">
                  Genopets, a global Move-to-Earn gaming app that rewards players for an active lifestyle, saw rapid growth upon launch. Unfortunately this attracted the attention of malicious users who were looking to abuse the backend API resources for their own benefit.
                </div>
                <Link href="/customer/genopets" className="!no-underline">
                  <div className="text-white bg-[#78A65A] mt-[25px] px-[38px] py-[16px] rounded-[7px] font-medium text-[16px] leading-[28px] text-center md:w-fit transition hover:bg-[#1b7609]">
                    Genopets's Customer Story
                  </div>
                </Link>
              </div>
            </div>
            
            <div className="mt-[50px] md:mt-[40px] bg-[#f2f5f9] rounded-[10px] py-[30px] px-[20px] lg:py-[40px] lg:px-[50px]">
              <div className="mb-[25px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] text-left lg:text-center my-0">
                  Protect your Reputation
                </h2>
                <div className="bg-[#B21C7A] h-[4px] mt-[10px] w-[162px] lg:mt-[15px] lg:w-[230px] lg:mx-auto"></div>
                <div className="text-[#262727] text-[17px] mt-[10px] leading-[29px] font-medium lg:mt-[15px]">
                  For retailers, reputation is everything. With any hint of data breaches, exposure of private
                  information, misuse of purchase history, or theft of credit card numbers, shoppers will abandon
                  your store or service forever. Approov can prevent data breaches by blocking attacks such as:
                </div>
              </div>
              <div className="w-full block flex-wrap lg:flex mt-[16px] md:mt-[50px]">
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Account Takeover
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        <Link href="/mobile-app-security/rasp/api-security/" className="!underline">Credential stuffing attacks</Link>  use credentials stolen from other site breaches or they repeatedly try
                        weak credential combinations to take over your customer accounts. Today, this likely makes up the
                        majority of your login traffic.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-0">
                  <p className="leading-[25px] text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Frustrates <Link href="https://approov.io/blog/how-can-bots-be-prevented" className="!underline">brute force attacks</Link> by only accepting login requests coming from your legitimate
                        shopping app.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Gift Card Cracking
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Gift cards with valid balances can be identified and then used or sold without requiring login
                        credentials.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Requires that gift cards be applied through your mobile app to protect your gift card value from
                        brute force crackers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[50px] md:mt-0 md:max-w-[280px]">
            <RightSidebar />
          </div>
        </Container>
      </Section>
    </PageLayout>
  );
};

export default OtherMarketPage;
